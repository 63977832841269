import React from 'react'
import styled from '@emotion/styled'
import { util, typography, colors } from 'src/styles'
import Button from 'src/components/Button'
import { uiElementSizes } from 'src/styles/globals'

const Wrapper = styled.div`
	${ ({ size }) => `
		${ util.responsiveStyles('height', uiElementSizes[size] * 1.3, uiElementSizes[size], uiElementSizes[size], uiElementSizes[size]) }
	` }
	
	padding: 2px;
	display: inline-flex;
	align-items: stretch;
	justify-content: stretch;
`

const ControlButton = styled(Button)`
	color: ${ colors.mainColor };
	font-family: ${ typography.secondaryFont };
`

const InnerContent = styled.div`
	display: flex;
	align-items: center;
`

const ValueWrapper = styled.div`
	${ ({ size }) => `
		${ typography[size] }
	` }
	width: 1.6em;
	text-align: center;
`

const buttonSize = {
	tiny: 'tiny',
	small: 'tiny',
	medium: 'small',
	large: 'small'
}

const textSize = {
	tiny: 'bodySmall',
	small: 'bodySmall',
	medium: 'body',
	large: 'bodyMedium'
}

const QuantityInput = ({ className, size = 'medium', quantity = 1, updateQuantity, increaseQuantity = () => {}, decreaseQuantity = () => {} }) => {
	let decrease = decreaseQuantity
	let increase = increaseQuantity
	if (updateQuantity) {
		decrease = () => updateQuantity(quantity - 1)
		increase = () => updateQuantity(quantity + 1)
	}

	return (
		<Wrapper className={className} size={size}>
			<InnerContent>
				<ControlButton
					shape='circle'
					icon='remove'
					setTheme='transparent'
					size={buttonSize[size]}
					onClick={decrease}
					disabled={quantity <= 1}
				/>
				<ValueWrapper size={textSize[size]}>{quantity}</ValueWrapper>
				<ControlButton
					shape='circle'
					icon='add'
					setTheme='transparent'
					size={buttonSize[size]}
					onClick={increase}
				/>
			</InnerContent>
		</Wrapper>
	)
}

export default QuantityInput
