import React, { Fragment, useContext } from 'react'
import styled from '@emotion/styled'
import { graphql, useStaticQuery } from 'gatsby'

import Grid from 'src/components/Grid'
import Button from 'src/components/Button'
import CartItem from './CartItem'
import { colors, animations, mq, util, typography } from 'src/styles'
import { IoMdClose } from 'react-icons/io'
import { ShopifyContext } from 'src/state/ShopifyState'
import { OverlayContext } from 'src/state/OverlayState'
import RoughEdgeLeftSvg from 'src/assets/images/rough-edge-left.svg'
import { toPrice, parseProductVariant } from 'src/utils/format'

const containerPadding = () => `
	${ util.responsiveStyles('padding-left', 70, 55, 55, 26) }
	${ util.responsiveStyles('padding-right', 70, 55, 55, 26) }
`

const containerPaddingVertical = side => `
	${ !side || side === 'top' ? `
		${ util.responsiveStyles('padding-top', 70, 55, 55, 26) }
	` : '' }
	${ !side || side === 'bottom' ? `
		${ util.responsiveStyles('padding-bottom', 70, 55, 55, 26) }
	` : '' }
`

const CloseButton = styled(Button)`
	cursor: pointer;
	${ util.responsiveStyles('padding-top', 27, 17, 15, 15) }
	display: flex;
	justify-content: center;
	align-items: flex-start;
	margin: -12px;
`

const Close = styled(IoMdClose)`
`

const ShadedWrapper = styled.div`
	position: fixed;
	top: 0;
	right: 0;
	z-index: 11;
	display: flex;
	align-items: stretch;
	justify-content: flex-end;
	width: 100%;
	height: 100%;
	overflow-y: hidden;
	color: ${ colors.textColor };
	overflow: hidden;
	${ ({ isOpen }) => !isOpen ? `
		pointer-events: none;
	` : '' }
`

const Panel = styled.div`
	height: 100%;
	width: 50%;
	max-width: 700px;
	position: fixed;
	transition: transform ${ animations.slowSpeed } cubic-bezier(0.44, 0.24, 0.16, 1.00);
	min-width: 600px;
	background: ${ colors.bgColor };
	${ mq.mediumAndBelow } {
		width: 100%;
		min-width: 100%;
	}
	${ ({ isOpen }) => isOpen ? `
		transform: none;
	` : `
		transform: translate3d(105%, 0, 0);
		pointer-events: none;
	` }
`

const InnerWrapper = styled.div`
	display: flex;
	flex-direction: column;
	align-items: stretch;
	height: 100%;
`

const HeaderContainer = styled.div`
	${ containerPadding() }
	${ util.responsiveStyles('padding-top', 40, 36, 30, 24) }
	${ util.responsiveStyles('padding-bottom', 40, 36, 30, 24) }
	display: flex;
	justify-content: space-between;
	width: 100%;
	align-items: center;
	flex-grow: 0;
	flex-shrink: 0;
	position: relative;
	h6 {
		margin: 0;
	}
	hr {
		position: absolute;
		${ util.responsiveStyles('left', 70, 55, 55, 26) }
		${ util.responsiveStyles('right', 70, 55, 55, 26) }
		bottom: 0;
		border-color: ${ colors.textColor };
		margin: 0;
	}
`
const ContentContainer = styled.div`
	${ containerPadding() }
	flex-grow: 1;
	flex-shrink: 1;
	overflow: auto;
	${ containerPaddingVertical('bottom') }
	${ ({ empty }) => empty ? `
		color: ${ colors.lightTextColor };
		text-align: center;
		${ typography.bodyMedium }
		font-weight: 500;
		display: flex;
		align-items: center;
		justify-content: center;
	` : '' }
`
const FooterContainer = styled.div`
	${ containerPadding() }
	${ containerPaddingVertical('bottom') }
	flex-grow: 0;
	flex-shrink: 0;
`

const Overlay = styled.div`
	width: 100%;
	height: 100%;
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background: ${ colors.pink3 };
	transition: opacity ${ animations.slowSpeed } cubic-bezier(0.44, 0.24, 0.16, 1.00);
	${ ({ isOpen }) => isOpen ? `
		opacity: .5;
		${ mq.mediumAndBelow } {
			opacity: .6;
		}
	` : `
		opacity: 0;
	` }
`

const CheckoutButton = styled(Button)``

const LineItem = styled.dl`
	display: flex;
	align-items: center;
	justify-content: space-between;
	border-top: 1px solid ${ colors.textColor };
	${ util.responsiveStyles('padding-top', 30, 24, 20, 18) }
	${ util.responsiveStyles('padding-bottom', 30, 24, 20, 18) }
	dt {
		${ typography.h6 }
	}
	dd {
		${ typography.body }
		font-weight: 500;
		b {
			${ typography.bodyLarge };
			font-weight: 500;
		}
	}
`

const StyledRoughEdge = styled(RoughEdgeLeftSvg)`
	top: 0;
	bottom: 0;
	right: calc(100% - 2px);
	position: absolute;
	height: 100%;
	${ util.responsiveStyles('width', 14, 12, 10, 8) }
	* {
		fill: ${ colors.bgColor };
	}
`

const Cart = () => {
	let empty = true

	const {
		store: { checkout },
	} = useContext(ShopifyContext)

	const {
		cartOpen,
		toggleCart
	} = useContext(OverlayContext)

	if (checkout && checkout.lineItems.length > 0) {
		empty = false
	}

	const isOpen = cartOpen

	const { allShopifyProduct } = useStaticQuery(graphql`
		query {
			allShopifyProduct {
				edges {
					node {
						images: media {
							... on ShopifyMediaImage {
								image {
									gatsbyImageData
								}
							}
						}
						id
						title
						variants {
							id
							availableForSale
							price
							shopifyId
							compareAtPrice
							image {
								gatsbyImageData
							}
						}
						shopifyId
					}
				}
			}
		}
	`)
	// const merchItems = allShopifyProduct.edges
	const merchItems = allShopifyProduct.edges.map((product, index) => {
		let productImage = product.node.images[0]
		const variantIds = product.node.variants.map((variant, index) => {
			if (variant?.image?.image?.gatsbyImageData) {
				productImage = variant.image
			}
			return parseProductVariant(variant.shopifyId)
		})
		const merchFiltered = { ids: variantIds, image: productImage }
		return merchFiltered
	})

	checkout.lineItems.map((item, index) => {
		const itemId = parseProductVariant(item.variant.id)
		const merchItem = merchItems.filter(merch => merch.ids.includes(itemId))[0]
		const merchImage = merchItem.image
		item.customImage = merchImage
		return false
	})

	return (
		<ShadedWrapper isOpen={isOpen}>
			<Overlay onClick={toggleCart} isOpen={isOpen} />
			<Panel
				isOpen={isOpen}
				colGap="0"
				rowGap="0"
			>
				<InnerWrapper>
					<Fragment>
						<HeaderContainer>
							<h6>Your Cart</h6>
							<CloseButton
								onClick={toggleCart}
								shape="circle"
								size="small"
								setTheme="transparent"
								icon={<Close size={26} />}
								title='Close Cart'
							/>
							{!empty && (<hr/>)}
						</HeaderContainer>
						<ContentContainer empty={empty}>
							{checkout.lineItems.map((item, index) => {
								return (
									<CartItem
										item={item}
										key={'cart-item-' + item.id}
										firstItem={index === 0}
									/>
								)
							})}
							{empty && (
								<h4>Your cart is empty</h4>
							)}
						</ContentContainer>
						<FooterContainer>
							{!empty && (
								<LineItem size='large'>
									<dt>Subtotal</dt>
									<dd><b>{toPrice(checkout.subtotalPrice.amount)}</b></dd>
								</LineItem>
							)}
							<Grid small="[1]" rowGap="12px">
								{!empty ? (
									<CheckoutButton external={true} to={checkout && checkout.webUrl} size='large' title='Checkout'>Checkout</CheckoutButton>
								) : (
									<Button onClick={toggleCart} setTheme={empty ? 'default' : 'goldHollow'} title='Continue Shopping'>Continue Shopping</Button>
								)}
							</Grid>
						</FooterContainer>
					</Fragment>
				</InnerWrapper>
				<StyledRoughEdge />
			</Panel>
		</ShadedWrapper>
	)
}

export default Cart
