import React, { useContext, useState } from 'react'
import styled from '@emotion/styled'
import { Link } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'
import Grid from 'src/components/Grid'
import { colors, animations, mq, typography, util } from 'src/styles'
import { AiOutlineDelete } from 'react-icons/ai'
import { ShopifyContext } from 'src/state/ShopifyState'
import AnimateHeight from 'react-animate-height'
import QuantityInput from 'src/components/QuantityInput'
import Button from 'src/components/Button'
import { toPrice, getProductVariant } from 'src/utils/format'

const TextWrapper = styled.div`
  max-width: 100%;
  ${ typography.bodySmall }
  display: flex;
  flex-direction: column;
`

const ImageLink = styled(Link)`
  display: block;
  &:hover {
    opacity: .8;
  }
`

const PriceWrapper = styled.div`
  ${ typography.bodySmall }
  text-align: right;
  color: ${ colors.textColor };
  line-height: 1em;
  transition: opacity ${ animations.mediumSpeed } ease-in-out;
  ${ ({ updating }) => updating ? `
    opacity: .4;
  ` : '' }
  .slashed-price {
    display: inline-block;
    margin-bottom: .4em;
    position: relative;
    color: ${ colors.pink5 };
    &:after {
      content: '';
      display: block;
      position: absolute;
      top: 50%;
      left: 0;
      width: 100%;
      height: 1px;
      background: currentcolor;
    }
  }
  b {
    font-weight: ${ typography.bold };
  }
`

const ItemWrapper = styled.div`
  margin-top: 16px;
  display: grid;
  column-gap: 16px;
  grid-template-columns: 120px auto;
  align-items: center;
  ${ ({ firstItem }) => firstItem ? '' : `
    border-top: 1px solid ${ colors.hrColor };
    padding-top: 16px;
  ` }
  ${ mq.extraLargeAndBelow } {
    grid-template-columns: 100px auto;
  }
  ${ mq.mediumAndBelow } {
    grid-template-columns: 80px auto;
  }
`

const CartItemImage = styled(GatsbyImage)``

const Title = styled.div`
  ${ typography.h6 }
`

const Variants = styled.div`
  ${ typography.tinyCaps }
  color: ${ colors.lightTextColor };
`

const OptionsRow = styled.div`
  display: flex;
  align-items: center;
  ${ util.responsiveStyles('margin-top', 10, 10, 8, 6) }
  ${ util.responsiveStyles('margin-left', -18, -16, -16, -16) }
  transition: opacity ${ animations.mediumSpeed } ease-in-out;
  ${ ({ updating }) => updating ? `
    opacity: .4;
    pointer-events: none;
    cursor: wait;
  ` : '' }
`

const RemoveButton = styled(Button)``

const CartItem = props => {
  const { item, firstItem } = props
  const {
    removeLineItem,
    updateLineItem,
    store: { checkout, updatingItem },
  } = useContext(ShopifyContext)

  const [removed, setRemoved] = useState(false)

  const checkoutId = checkout.id
  const itemId = item.id
  const itemQuantity = item.quantity

  const removeItem = () => {
    removeLineItem(checkoutId, itemId)
    setRemoved(true)
  }

  const discountPrice = item?.variant?.compareAtPrice

  return (
    <AnimateHeight
      duration={300}
      delay={0}
      animateOpacity={false}
      height={removed ? 0 : 'auto'}
    >
    <ItemWrapper firstItem={firstItem}>
      <div>
        <ImageLink to={`/shop/${ item.variant.product.handle }/`}>
          {item.variant.image && (
            <CartItemImage
              alt={`${ item.title } product shot`}
              image={item?.customImage?.image?.gatsbyImageData}
            />
          )}
        </ImageLink>
      </div>
      <div>
        <Grid
          small="[13] 1 [4]" // 18 units to work with
          medium="[8] 1 [3]" // 14 units to work with
          large="[6] 1 [2]" // 12 units to work with
          extraLarge="[6] 1 [2]" // 6 units to work with
          rowGap="0"
          colGap="0"
          vAlign="baseline"
        >
          <TextWrapper>
            <Title>{item.title}</Title>
            {getProductVariant(item) && (<Variants>{getProductVariant(item)}</Variants>)}
            <OptionsRow updating={updatingItem === itemId}>
              <QuantityInput
                quantity={itemQuantity}
                size='tiny'
                decreaseQuantity={() => updateLineItem(checkoutId, itemId, itemQuantity - 1)}
                increaseQuantity={() => updateLineItem(checkoutId, itemId, itemQuantity + 1)}
              />
              <RemoveButton
                hoverColor={colors.pink5}
                onClick={removeItem}
                icon={<AiOutlineDelete size="18px"/>}
                size='tiny'
                shape='circle'
                setTheme='transparent'
              />
              {/*
              <RemoveLink onClick={removeItem}>
                REMOVE
              </RemoveLink>
              */}
            </OptionsRow>
          </TextWrapper>
          <PriceWrapper updating={updatingItem === itemId}>
            {discountPrice ? (
              <div>
                <span className='slashed-price'>{toPrice(item.variant.price.amount)}</span><br/>
                <b>{'$' + (parseInt(discountPrice) * itemQuantity).toFixed(2)}</b>
              </div>
            ) : (
              toPrice(item.variant.price.amount)
            )}
          </PriceWrapper>
        </Grid>
      </div>
    </ItemWrapper>
    </AnimateHeight>
  )
}

export default CartItem
